<template>
  <div class="phone-field">
    <div
      :class="[
        'phone-field__wrap',
        { 'phone-field__wrap--error' : (error || checkValidate) && !phoneValid },
        { round: roundBorder }
      ]"
      @click="focusOnInput"
    >
      <button
        v-if="currCountry && !withoutMask"
        v-click-outside="closeDrop"
        class="phone-field__num"
        @click="setDropDownState"
      >
        <div class="phone-field__country">
          <img
            class="phone-field__flag"
            :src="currCountry.flag"
            alt="flag"
          >
        </div>

        <img
          class="phone-field__arrow"
          src="/v2/main/arrow.svg"
          alt="arrow"
        >
      </button>

      <input
        v-if="withoutMask"
        ref="phoneMask"
        v-model="phone"
        type="tel"
        :class="['phone-field__input phone-field__input--without-mask', { round: roundBorder }]"
        autocomplete="phone"
        :placeholder="placeholder"
        @input="$emit('clearSubmitError', 'phone')"
      >
      <the-mask
        v-else
        ref="phoneMask"
        v-model="phone"
        :mask="mask"
        :class="['phone-field__input', { round: roundBorder }]"
        type="tel"
        :placeholder="placeholder"
        autocomplete="phone"
        @input="$emit('clearSubmitError', 'phone')"
      />

      <div
        :class="[
          'phone-field__error',
          { 'phone-field__error--active' : (error || checkValidate) && !phoneValid },
        ]"
      >
        {{ curError ? curError : errorMessage ? errorMessage : '' }}
      </div>
    </div>

    <ul
      v-if="!withoutMask"
      :class="['phone-field__list', { 'phone-field__list--active' : toggleDropdown }]"
    >
      <li
        v-for="item in currCountries"
        :key="item.iso"
        class="phone-field__list-item"
        @click="changeCountry(item)"
      >
        <img
          class="phone-field__icon-flag"
          :src="item.flag"
          alt="flag"
        >
        {{ item.code }}
        {{ item.name }}

        <template v-if="item.ruName">
          ({{ item.ruName }})
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import vClickOutside from 'v-click-outside';
import countries from './countryCode';

export default {
  name: 'FormPhoneField',
  components: { TheMask },

  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: [String, null],
      default: null,
    },

    errorsList: {
      type: Array,
      default: () => [],
    },
    /*
    id: {
      type: String || Number,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    */

    placeholder: {
      type: String,
      default: 'Телефон',
    },
    roundBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      toggleDropdown: false,
      phone: '',
      phoneValid: false,
      currCountry: null,
      mask: '',
      onlyCountries: ['RU', 'BY'],
      currCountries: [],
      flag: null,
      curError: '',
      checkValidate: false,
    };
  },
  computed: {
    withoutMask() {
      return this.$route.name === 'online-school-ceny-dubai';
    },
  },
  watch: {
    phone() {
      this.$emit('updatePhone', (this.currCountry.code + this.phone).replace(/[^\d]/g, ''));
      this.checkValid();
    },
    errorsList() {
      if (this.errorsList.some((elem) => elem.objectField === 'phone')) {
        this.phoneValid = false;
        this.curError = (this.errorsList.find((elem) => elem.objectField === 'phone').message);
      } else {
        this.curError = '';
        this.phoneValid = true;
      }
    },
  },
  created() {
    this.onlyCountries.forEach((element) => {
      this.currCountries.push(countries.find(({ iso }) => iso === element));
    });

    if (this.currCountries.length) {
      this.mask = `${this.currCountries[0].code} ${this.currCountries[0].mask}`;
      this.currCountry = JSON.parse(JSON.stringify(this.currCountries[0]));
    }
  },
  methods: {
    closeDrop() {
      this.toggleDropdown = false;
    },

    changeCountry(e) {
      const codeWithoutPlus = this.currCountry.code.replace('+', '');
      const regex = new RegExp(`\\+?${codeWithoutPlus}`);
      this.phone = this.phone.replace(regex, '');

      this.currCountry = e;
      this.closeDrop();
      const mask = countries.find(({ iso }) => iso === e.iso);
      if (mask) {
        this.mask = `${mask.code} ${mask.mask}`;
      }
    },

    focusOnInput() {
      if (this.withoutMask) return;

      this.$refs.phoneMask.$el?.focus?.();

      if (this.phone === '') {
        this.phone = this.currCountry.code;
      }
    },

    setDropDownState() {
      this.toggleDropdown = !this.toggleDropdown;
      this.$emit('updatePhone', (this.currCountry.code + this.phone).replace(/[^\d]/g, ''));
    },

    checkValid() {
      if (this.withoutMask) {
        this.phoneValid = Boolean(this.phone.length);
        this.curError = this.phoneValid ? '' : 'Укажите свой номер';
        return;
      }

      if (this.phone.length !== this.mask.match(/#/g).length) {
        this.phoneValid = false;
        this.curError = this.errorMessage ? this.errorMessage : 'Укажите свой номер';
      } else if (this.errorsList.some((elem) => elem.objectField === 'phone')) {
        this.phoneValid = false;
        this.curError = (this.errorsList.find((elem) => elem.objectField === 'phone').message);
      } else {
        this.phoneValid = true;
      }
    },

    reset() {
      this.checkValidate = false;
      this.phone = '';
      this.curError = '';
    },

    validate() {
      this.checkValidate = true;
      this.checkValid();
      return this.phoneValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-field {
  position: relative;

  &__wrap {
    position: relative;
    z-index: 2;

    padding: 22px 20px 24px 20px;

    height: 70px;

    border-radius: 15px;
    border: 2px solid $black;

    background: $white-color;

    @include media-down(tablet) {
      height: 58px;
    }

    &--error {
      border-color: #ff0040;

      .phone-field__input--without-mask {
        border-color: #ff0040 !important;
      }
    }
  }

  &__wrap.round {
    border-radius: 100px;
  }

  &__num {
    position: absolute;
    z-index: 1;
    height: 14px;
    top: calc(50% - 7px);

    display: flex;
    align-items: center;
    gap: 8px;

    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
  }

  &__flag {
    display: block;

    width: 20px;

    object-fit: contain;
  }

  &__arrow {
    transform: rotate(180deg);
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;
    padding-left: 65px;
    border: none;

    font-size: 20px;

    @include media-down(tablet) {
      font-size: 18px;
    }

    &--without-mask {
      padding-left: 1em;
    }
  }

  &__input.round {
    border-radius: 100px;
  }

  &__error {
    position: absolute;
    top: -7px;
    right: 20px;

    padding: 0 5px;

    background: $white-color;

    font-size: 14px;
    line-height: 14px;
    color: #ff0040;

    opacity: 0;
    transition: 0.2s ease-out;

    &--active {
      opacity: 1;
    }
  }

  &__list {
    position: absolute;
    z-index: 3;
    right: 0;
    top: calc(100% + 4px);
    left: 0;

    border-radius: 15px;
    border: 2px solid $black;

    background: $white-color;

    opacity: 0;
    visibility: hidden;
    transform: translateY(-50px);
    transition: 0.2s ease-out;

    &--active {
      transform: translateY(0);

      visibility: visible;
      opacity: 1;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 12px;

    cursor: pointer;
    transition: 0.2s ease-out;

    @media (hover: hover) {
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__icon-flag {
    width: 24px;
    height: 24px;
  }
}
</style>
