var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-field"},[_c('div',{class:[
      'phone-field__wrap',
      { 'phone-field__wrap--error' : (_vm.error || _vm.checkValidate) && !_vm.phoneValid },
      { round: _vm.roundBorder }
    ],on:{"click":_vm.focusOnInput}},[(_vm.currCountry && !_vm.withoutMask)?_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDrop),expression:"closeDrop"}],staticClass:"phone-field__num",on:{"click":_vm.setDropDownState}},[_c('div',{staticClass:"phone-field__country"},[_c('img',{staticClass:"phone-field__flag",attrs:{"src":_vm.currCountry.flag,"alt":"flag"}})]),_vm._v(" "),_c('img',{staticClass:"phone-field__arrow",attrs:{"src":"/v2/main/arrow.svg","alt":"arrow"}})]):_vm._e(),_vm._v(" "),(_vm.withoutMask)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],ref:"phoneMask",class:['phone-field__input phone-field__input--without-mask', { round: _vm.roundBorder }],attrs:{"type":"tel","autocomplete":"phone","placeholder":_vm.placeholder},domProps:{"value":(_vm.phone)},on:{"input":[function($event){if($event.target.composing)return;_vm.phone=$event.target.value},function($event){return _vm.$emit('clearSubmitError', 'phone')}]}}):_c('the-mask',{ref:"phoneMask",class:['phone-field__input', { round: _vm.roundBorder }],attrs:{"mask":_vm.mask,"type":"tel","placeholder":_vm.placeholder,"autocomplete":"phone"},on:{"input":function($event){return _vm.$emit('clearSubmitError', 'phone')}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_vm._v(" "),_c('div',{class:[
        'phone-field__error',
        { 'phone-field__error--active' : (_vm.error || _vm.checkValidate) && !_vm.phoneValid },
      ]},[_vm._v("\n      "+_vm._s(_vm.curError ? _vm.curError : _vm.errorMessage ? _vm.errorMessage : '')+"\n    ")])],1),_vm._v(" "),(!_vm.withoutMask)?_c('ul',{class:['phone-field__list', { 'phone-field__list--active' : _vm.toggleDropdown }]},_vm._l((_vm.currCountries),function(item){return _c('li',{key:item.iso,staticClass:"phone-field__list-item",on:{"click":function($event){return _vm.changeCountry(item)}}},[_c('img',{staticClass:"phone-field__icon-flag",attrs:{"src":item.flag,"alt":"flag"}}),_vm._v("\n      "+_vm._s(item.code)+"\n      "+_vm._s(item.name)+"\n\n      "),(item.ruName)?[_vm._v("\n        ("+_vm._s(item.ruName)+")\n      ")]:_vm._e()],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }