<template>
  <div :class="['fieldset', { 'has-error': checkValidate && errorMessage }]">
    <input
      v-model="field"
      type="text"
      :placeholder="placeholder"
      :class="[{ round: roundBorder }]"
    >
    <div
      v-if="checkValidate && errorMessage"
      class="error-message"
    >
      {{ errorMessage ? errorMessage : 'Ошибка' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormTextField',
  props: {
    propValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Имя',
    },
    roundBorder: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, null],
      default: null,
    },
  },
  data: () => ({
    field: '',
    checkValidate: false,
  }),
  watch: {
    propValue: {
      immediate: true,
      handler() {
        this.field = this.propValue;
      },
    },
    field(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    validate() {
      return true;
    },
    reset() {
      this.field = '';
    },
  },
};
</script>

<style scoped lang="scss">

.fieldset {
  position: relative;

  &.has-error input {
    border-color: #FF0040 !important;
  }

  input.round {
    border-radius: 100px;
  }

  .error-message {
    color: #FF0040;
    text-align: right;
    font-size: 14px;
    line-height: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 20px;
    padding: 0 5px;
    background-color: $white-color;
  }

  .round {
    border-radius: 100px !important;
  }
}

</style>
